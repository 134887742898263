import mapboxgl from 'mapbox-gl';
const twgl = require('twgl.js');

export function VectorField(map, gl) {
  let data;
  let bounds;
  let range;
  let programInfo;
  let textures;
  let screenProgramInfo;
  let updateProgramInfo;
  let particleTextures;
  let numParticles;
  let framebuffer;
  let particleIndices;
  let particleRes;
  let state = 'PAUSED';
  let mapBounds;

  let fadeOpacity;
  let speedFactor;
  let speedFactorWave;
  let dropRate;
  let dropRateBump;

  let animationId;

  let selectedLayer;
  const nParticles = 750;

  function setBounds(bounds) {
    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();

    const nwMercator = mapboxgl.MercatorCoordinate.fromLngLat(nw);
    const seMercator = mapboxgl.MercatorCoordinate.fromLngLat(se);

    // minx miny maxx maxy
    mapBounds = [nwMercator.x, seMercator.y, seMercator.x, nwMercator.y];
  }

  function setData(dataObject, selectedLayerName) {
    selectedLayer = selectedLayerName;
    // set vectorField data and bounds of data, and range of vector components
    ({ data, bounds, range } = dataObject);

    // initialize settings, programs, buffers

    // data is an img element
    initialize(data, selectedLayerName);

    // start animating field
    startAnimation();
  }

  function setParticles(num) {
    particleRes = Math.ceil(Math.sqrt(num));
    numParticles = particleRes * particleRes;

    const particleState = new Uint8Array(numParticles * 4);

    for (let i = 0; i < particleState.length; i++) {
      particleState[i] = Math.floor(Math.random() * 256);
    }

    particleTextures = twgl.createTextures(gl, {
      particleTexture0: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: particleRes,
        height: particleRes,
        format: gl.RGBA,
        src: particleState,
        wrap: gl.CLAMP_TO_EDGE,
      },
      particleTexture1: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: particleRes,
        height: particleRes,
        format: gl.RGBA,
        src: particleState,
        wrap: gl.CLAMP_TO_EDGE,
      },
    });

    particleIndices = new Float32Array(numParticles);
    for (let i = 0; i < numParticles; i++) {
      particleIndices[i] = i;
    }
  }

  function initialize(imgElement, selectedLayerName) {
    const fs = getFS(selectedLayerName);
    //   get raw data from img element by converting it to canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    // var img = document.getElementById('myimg');
    canvas.width = imgElement.width;
    canvas.height = imgElement.height;
    context.drawImage(imgElement, 0, 0);
    const myData = context.getImageData(0, 0, imgElement.width, imgElement.height);

    //   data is an <img> element
    fadeOpacity = 0.985;
    speedFactor = 0.075;
    speedFactorWave = 0.035;
    dropRate = 0.003;
    dropRateBump = 0.05;

    programInfo = twgl.createProgramInfo(gl, [vs, fs]);
    screenProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsScreen]);
    updateProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsUpdate]);

    // initial setting of particle positions
    setParticles(nParticles);

    const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4);

    textures = twgl.createTextures(gl, {
      u_image: {
        mag: gl.LINEAR,
        min: gl.LINEAR,
        width: myData.width,
        height: myData.height,
        format: gl.RGBA,
        src: myData.data,
      },
      backgroundTexture: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: gl.canvas.width,
        height: gl.canvas.height,
        format: gl.RGBA,
        src: emptyPixels,
        wrap: gl.CLAMP_TO_EDGE,
      },
      screenTexture: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: gl.canvas.width,
        height: gl.canvas.height,
        format: gl.RGBA,
        src: emptyPixels,
        wrap: gl.CLAMP_TO_EDGE,
      },
    });

    framebuffer = gl.createFramebuffer();
  }

  function drawParticles(isCIOPSWest, isCurrents) {
    gl.useProgram(programInfo.program);

    const arrays = {
      a_index: {
        numComponents: 1,
        data: particleIndices,
      },
    };

    const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);

    const uniforms = {
      u_vector: textures.u_image,
      u_particles: particleTextures.particleTexture0,
      u_particles_res: particleRes,
      size: 2 * window.devicePixelRatio,
      u_vector_min: [range[0], range[0]],
      u_vector_max: [range[1], range[1]],
      u_bounds: mapBounds,
      u_data_bounds: bounds,
      isCIOPSWest,
      isCurrents,
    };

    twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo);
    twgl.setUniforms(programInfo, uniforms);

    twgl.drawBufferInfo(gl, bufferInfo, gl.POINTS);
  }

  function drawTexture(texture, opacity) {
    gl.useProgram(screenProgramInfo.program);

    const arrays = {
      a_pos: {
        numComponents: 2,
        data: new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
      },
    };

    const uniforms = {
      u_screen: texture,
      u_opacity: opacity,
    };

    const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
    twgl.setBuffersAndAttributes(gl, screenProgramInfo, bufferInfo);
    twgl.setUniforms(screenProgramInfo, uniforms);
    twgl.drawBufferInfo(gl, bufferInfo);
  }

  function drawScreen(isCIOPSWest, isCurrents) {
    // bind framebuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    // draw to screenTexture
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      textures.screenTexture,
      0
    );
    // set viewport to size of canvas

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // first disable blending
    gl.disable(gl.BLEND);

    // draw backgroundTexture to screenTexture target
    drawTexture(textures.backgroundTexture, fadeOpacity);
    // draw particles to screentexture
    drawParticles(isCIOPSWest, isCurrents);

    // target normal canvas by setting FRAMEBUFFER to null
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    // enable blending for final render to map
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    drawTexture(textures.screenTexture, 1.0);

    gl.disable(gl.BLEND);

    // swap background with screen
    const temp = textures.backgroundTexture;
    textures.backgroundTexture = textures.screenTexture;
    textures.screenTexture = temp;
  }

  function updateParticles() {
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      particleTextures.particleTexture1,
      0
    );

    gl.viewport(0, 0, particleRes, particleRes);

    gl.useProgram(updateProgramInfo.program);

    const arrays = {
      a_pos: {
        numComponents: 2,
        data: new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
      },
    };

    const uniforms = {
      u_vector: textures.u_image,
      u_particles: particleTextures.particleTexture0,
      u_vector_min: [range[0], range[0]],
      u_vector_max: [range[1], range[1]],
      u_rand_seed: Math.random(),
      u_vector_res: [data.width, data.height],
      u_speed_factor: selectedLayer === 'wave' ? speedFactorWave : speedFactor,
      u_drop_rate: dropRate,
      u_drop_rate_bump: dropRateBump,
      u_bounds: mapBounds,
      u_data_bounds: bounds,
    };

    const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
    twgl.setBuffersAndAttributes(gl, updateProgramInfo, bufferInfo);

    twgl.setUniforms(updateProgramInfo, uniforms);

    twgl.drawBufferInfo(gl, bufferInfo);

    const temp = particleTextures.particleTexture0;
    particleTextures.particleTexture0 = particleTextures.particleTexture1;
    particleTextures.particleTexture1 = temp;
  }

  function draw(isCIOPSWest, isCurrents) {
    if (state !== 'ANIMATING') return;

    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.STENCIL_TEST);

    drawScreen(isCIOPSWest, isCurrents);
    updateParticles();
  }

  function frame() {
    map.triggerRepaint();
    animationId = requestAnimationFrame(frame);
  }

  function startAnimation() {
    if (state === 'ANIMATING') return;

    state = 'ANIMATING';
    setBounds(map.getBounds());
    // todo remove this
    frame();
  }

  function stopAnimation() {
    state = 'PAUSED';
    clear();
    cancelAnimationFrame(animationId);
  }

  function clear() {
    gl.clearColor(0.0, 0.0, 0.0, 0.0);

    // clear framebuffer textures
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      textures.screenTexture,
      0
    );
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      textures.backgroundTexture,
      0
    );
    gl.clear(gl.COLOR_BUFFER_BIT);

    // generate new random particle positions
    setParticles(nParticles);

    // target normal canvas
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    // clear canvas
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  return {
    setData,
    startAnimation,
    stopAnimation,
    draw,
  };
}

const vs = `
precision highp float;

attribute float a_index;

uniform float size;
uniform sampler2D u_particles;
uniform float u_particles_res;

varying vec2 v_particle_pos;

const vec2 bitEnc = vec2(1.,255.);
const vec2 bitDec = 1./bitEnc;

// decode particle position from pixel RGBA
vec2 fromRGBA(const vec4 color) {
  vec4 rounded_color = floor(color * 255.0 + 0.5) / 255.0;
  float x = dot(rounded_color.rg, bitDec);
  float y = dot(rounded_color.ba, bitDec);
  return vec2(x, y);
}

void main() {
    vec4 color = texture2D(u_particles, vec2(
        fract(a_index / u_particles_res),
        floor(a_index / u_particles_res) / u_particles_res));

    // decode current particle position from the pixel's RGBA value
    v_particle_pos = fromRGBA(color);

        
    gl_PointSize = size;
    
    gl_Position = vec4(2.0 * v_particle_pos.x - 1.0, 1.0 - 2.0 * v_particle_pos.y, 0, 1);
}
`;
function getFS(selectedLayerName) {
  return `
precision highp float;

uniform sampler2D u_vector;
uniform vec2 u_vector_min;
uniform vec2 u_vector_max;
//uniform sampler2D u_color_ramp;

uniform vec4 u_bounds;
uniform vec4 u_data_bounds;
uniform bool isCIOPSWest;
uniform bool isCurrents;

varying vec2 v_particle_pos;

vec2 returnLonLat(float x_domain, float y_domain, vec2 pos) {

    //need value between 0 and 1, which fract accomplishes
    float mercator_x = fract(u_bounds.x + pos.x * x_domain);
    float mercator_y = u_bounds.w + pos.y * y_domain;

    float lon = mercator_x * 360.0 - 180.0;
    float lat2 = 180.0 - mercator_y * 360.0;
    float lat = 360.0 / 3.141592654 * atan(exp(lat2 * 3.141592654/180.0)) - 90.0;

    return vec2(lon, lat);
}

void main() {

    //convert from 0-1 to degrees for proper texture value lookup
    float x_domain = abs(u_bounds.x - u_bounds.z);
    float y_domain = abs(u_bounds.y - u_bounds.w);

    vec2 coordinate = returnLonLat(x_domain, y_domain, v_particle_pos);
    float lon = coordinate.x;
    float lat = coordinate.y;
if (
  
  ((isCurrents && isCIOPSWest) &&
    ((lat > 46.7 && lon > -123.4) || (lat > 47.9 && lon > -124.527) || (lat > 49.3 && lon > -125.6) || (lat > 49.7 && lon > -126.0926) || (lon > -123.2))
  ) ||
  
   ((isCurrents && !isCIOPSWest) && ((lat > 50. && lon < -126.0926)|| (lat < 49. && lon < -124.527) || (lat < 49.8 && lon < -125.1) || (lat < 48.0 && lon < -123.26) ||
        (lat < 48.368 && lon < -124.651) || (lat < 49.46 && lat > 49.26 && (lon > -123.145 && lon < -122.816 )))) ||
  lat > u_data_bounds.w ||
  lat < u_data_bounds.y ||
  lon > u_data_bounds.z ||
  lon < u_data_bounds.x
  
) {
  discard;
}

    // Calculate the position for vector lookup
    vec2 vectorLookup = vec2(
      (lon - u_data_bounds.x) / (u_data_bounds.z - u_data_bounds.x),
      (lat - u_data_bounds.y) / (u_data_bounds.w - u_data_bounds.y)
    );

    // Get the velocity vector
    vec2 velocity = texture2D(u_vector, vectorLookup).rg;
    
    // Normalize the velocity
    velocity = mix(u_vector_min, u_vector_max, velocity);

    // Calculate the speed (magnitude of velocity)
    float speed = length(velocity);

    // Set a threshold for minimum speed (adjust this value as needed)
    float speedThreshold = 0.01 * length(u_vector_max);

    // Discard particles where speed is below the threshold
    if (speed < speedThreshold) {
      discard;
    }

    gl_FragColor = vec4(1.0, 1.0, 1.0, 0.33);
}
`;
}
const vsQuad = `
precision highp float;

attribute vec2 a_pos;

varying vec2 v_tex_pos;

void main() {
    v_tex_pos = a_pos;
    gl_Position = vec4(1.0 - 2.0 * a_pos, 0, 1);
}
`;
const fsScreen = `
precision highp float;

uniform sampler2D u_screen;
uniform float u_opacity;

varying vec2 v_tex_pos;
precision highp float;



uniform float u_value;
void main() {
    vec4 color = texture2D(u_screen, 1.0 - v_tex_pos);
    // a hack to guarantee opacity fade out even with a value close to 1.0
    gl_FragColor = vec4(floor(255.0 * color * u_opacity) / 255.0);
}
`;

const fsUpdate = `
precision highp float;

uniform sampler2D u_particles;
uniform sampler2D u_vector;
uniform vec2 u_vector_res;
uniform vec2 u_vector_min;
uniform vec2 u_vector_max;
uniform float u_rand_seed;
uniform float u_speed_factor;
uniform float u_drop_rate;
uniform float u_drop_rate_bump;
uniform vec4 u_bounds;
uniform vec4 u_data_bounds;

varying vec2 v_tex_pos;

const vec2 bitEnc = vec2(1.,255.);
const vec2 bitDec = 1./bitEnc;

// decode particle position from pixel RGBA
vec2 fromRGBA(const vec4 color) {
  vec4 rounded_color = floor(color * 255.0 + 0.5) / 255.0;
  float x = dot(rounded_color.rg, bitDec);
  float y = dot(rounded_color.ba, bitDec);
  return vec2(x, y);
}

// encode particle position to pixel RGBA
vec4 toRGBA (const vec2 pos) {
  vec2 rg = bitEnc * pos.x;
  rg = fract(rg);
  rg -= rg.yy * vec2(1. / 255., 0.);

  vec2 ba = bitEnc * pos.y;
  ba = fract(ba);
  ba -= ba.yy * vec2(1. / 255., 0.);

  return vec4(rg, ba);
}

// pseudo-random generator
const vec3 rand_constants = vec3(12.9898, 78.233, 4375.85453);
float rand(const vec2 co) {
    float t = dot(rand_constants.xy, co);
    return fract(sin(t) * (rand_constants.z + t));
}

// vector magnitude lookup; use manual bilinear filtering based on 4 adjacent pixels for smooth interpolation
vec2 lookup_vector(const vec2 uv) {
    // return texture2D(u_vector, uv).rg; // lower-res hardware filtering
    vec2 px = 1.0 / u_vector_res;
    vec2 vc = (floor(uv * u_vector_res)) * px;
    vec2 f = fract(uv * u_vector_res);
    vec2 tl = texture2D(u_vector, vc).rg;
    vec2 tr = texture2D(u_vector, vc + vec2(px.x, 0)).rg;
    vec2 bl = texture2D(u_vector, vc + vec2(0, px.y)).rg;
    vec2 br = texture2D(u_vector, vc + px).rg;
    return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
}

vec2 returnLonLat(float x_domain, float y_domain, vec2 pos) {

    //need value between 0 and 1, which fract accomplishes
    float mercator_x = fract(u_bounds.x + pos.x * x_domain);
    float mercator_y = u_bounds.w + pos.y * y_domain;

    float lon = mercator_x * 360.0 - 180.0;
    float lat2 = 180.0 - mercator_y * 360.0;
    float lat = 360.0 / 3.141592654 * atan(exp(lat2 * 3.141592654/180.0)) - 90.0;

    return vec2(lon, lat);
}

void main() {
    vec4 color = texture2D(u_particles, v_tex_pos);
    vec2 pos = fromRGBA(color);

    //convert from 0-1 to degrees for proper texture value lookup
    float x_domain = abs(u_bounds.x - u_bounds.z);
    float y_domain = abs(u_bounds.y - u_bounds.w);

    vec2 coordinate = returnLonLat(x_domain, y_domain, pos);
    float lon = coordinate.x;
    float lat = coordinate.y;

    float lon_domain = u_data_bounds.z - u_data_bounds.x;
    float lat_domain = u_data_bounds.w - u_data_bounds.y;

    vec2 pos_lookup = vec2(
        (lon - u_data_bounds.x) / lon_domain,
        (lat - u_data_bounds.y) / lat_domain
    );

    vec2 velocity = mix(u_vector_min, u_vector_max, lookup_vector(pos_lookup));
    
    float speed_t = length(velocity) / length(u_vector_max);
    

    // take EPSG:4236 distortion into account for calculating where the particle moved
    //float distortion = cos(radians(lat));
    vec2 offset = vec2(velocity.x , -velocity.y) * 0.0001 * u_speed_factor;
    // update particle position, wrapping around the date line
    
    pos = fract(1.0 + pos + offset);

    // a random seed to use for the particle drop
    vec2 seed = (pos + v_tex_pos) * u_rand_seed;

    // drop rate is a chance a particle will restart at random position, to avoid degeneration
    float drop_rate = u_drop_rate + speed_t * u_drop_rate_bump;
    float drop = step(1.0 - drop_rate, rand(seed));

    vec2 random_pos = vec2(
        rand(seed + 1.3),
        rand(seed + 2.1));
    pos = mix(pos, random_pos, drop);

    // encode the new particle position back into RGBA
    gl_FragColor = toRGBA(pos);
}
`;

export async function VectorImage(url, bounds, range) {
  // this function loads an image containing the vector data stored in R and G channels
  // It requires the bounds of the data in [minx, miny, maxx, maxy] and [min, max]
  // of the vector components used when encoding the field in the image

  // png image would usually be hosted at a url, and url would be a string.
  // For this demo, the data is processed in the notebook,
  // and the Canvas element is fed to this function in place of the url.
  if (url instanceof HTMLCanvasElement) {
    return {
      data: url,
      bounds,
      range,
    };
  }

  // bounds are [minx, miny, maxx, maxy]
  return new Promise((resolve, reject) => {
    const data = new Image();
    data.crossOrigin = `Anonymous`;
    data.src = url;

    data.onload = () => {
      resolve({
        data,
        bounds,
        range,
      });
    };
    data.onerror = () => reject(new Error('Error'));
  });
}
