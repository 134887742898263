import React, { useEffect, useState, useMemo, useLayoutEffect, memo } from 'react';
import Map from './Components/Map/Map.jsx';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import * as Sentry from '@sentry/react';
// Image files

import HorizontalLogo from './Images/oceanconnectlogo-horiz-white.svg';
import StationsHiddenIcon from './Images/Stations_hidden.png';
import AllStations from './Images/All_Stations.png';

import {
  CssBaseline,
  ThemeProvider,
  Box,
  Stack,
  IconButton,
  AppBar,
  Fab,
  Typography,
  useMediaQuery,
  Backdrop,
  Grid,
} from '@mui/material/';

import {
  GpsFixed,
  Settings as MenuIcon,
  Search as SearchIcon,
  Settings,
  WarningAmberRounded,
} from '@mui/icons-material';
import {
  defaultMapCenter,
  defaultMapZoom,
  defaultSettings,
  generateDateTime,
  minScreenHeight,
  minScreenWidth,
  server,
  useDebounce,
  useWindowSize,
  getPacificTime,
} from './utilities';
import StyledIcon from './Components/StyledIcon.jsx';

import mapLayers from './mapLayers.js';
import units from './units.js';
import stationTypes from './stations.js';
import TimeSlider from './Components/TimeSlider/TimeSlider.jsx';
import SettingsMenu from './Components/SettingsMenu.jsx';
import SearchMenu from './Components/SearchMenu.jsx';
import VariableSelectMenu from './Components/VariableSelectMenu.jsx';

import DataDrawer from './Components/DataDrawer/DataDrawer.jsx';
import DataPanel from './Components/DataPanel/DataPanel.jsx';

import Legend from './Components/Legend/Legend.jsx';
import './styles.css';
import Webcams from './Components/Webcams.jsx';

import theme from './theme.js';
import axios from 'axios';
import StationsMenu from './Components/StationsMenu.jsx';
import Crosshair from './Components/Crosshair/Crosshair.jsx';
import Welcome from './Components/Welcome.jsx';
import Spinner from './Components/Spinner.jsx';
import MailchimpModal from './Components/MailchimpModal.jsx';
const MemoizedDataPanel = memo(DataPanel);
const REFRESH_INTERVAL = 1;

// MEDIA QUERIES
const SSVStationsAPI = '/stations/list/';
const SSVPointsAPI = '/datapin/';

export default function App() {
  // MAP LAYER ENDTIMES
  const [mapLayerMaxTimes, setMapLayMaxTimes] = useState();
  const [tileTimesLookup, setTileTimesLookup] = useState();

  // SCREEN SIZE
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const size = useWindowSize();
  const screenIsShort = size.height < minScreenHeight;
  const screenIsNarrow = size.width < minScreenWidth;

  // TIME SLIDER
  const startDate = new Date(getPacificTime());

  startDate.setDate(startDate.getDate() - 1);
  startDate.setHours(0, 0, 0, 0);
  const [sliderPlayTime, setSliderPlayTime] = useState(false);

  const [sliderCurrentTime, setSliderCurrentTime] = useState(
    new Date(getPacificTime()).getTime()
  );
  const [landOrWater, setLandOrWater] = useState();

  // Search
  const [searchOpen, setSearchOpen] = useState(false);

  // Settings menu
  const [settings, setSettings] = useState(defaultSettings);
  const settingsCookieName = 'ocSettings';

  useEffect(() => {
    localStorage.setItem(settingsCookieName, JSON.stringify(settings));
  }, [settings]);

  function changeUnits(mapLayer) {
    const layerUnit = mapLayers.find((e) => e.variableName === mapLayer).unit;
    const { category } = units.find((e) => e.name === layerUnit);
    const unitsInCategory = units
      .filter((e) => e.category === category)
      .map((e) => e.name);

    const currentUnit = settings.units[category];
    const currentIndex = unitsInCategory.indexOf(currentUnit);

    const nextUnits = unitsInCategory[(currentIndex + 1) % unitsInCategory.length];
    const nextUnitsSettings = { ...settings.units };

    nextUnitsSettings[category] = nextUnits;
    setSettings({
      ...settings,
      units: nextUnitsSettings,
    });
  }

  // COOKIE CONSENT AND TERMS AGREEMENT
  const consentCookieName = 'ocConsent';
  const [cookieConsent, setCookieConsent] = useState();
  const ocTermsAgreementCookieName = 'ocTermsAgreement';
  const [ocTermsAgreement, setOCTermsAgreement] = useState();
  const welcomeClosedCookieName = 'ocWelcomeClosed';
  const [welcomeClosed, setWelcomeClosed] = useState(true);

  // MAP
  const [zoom, setZoom] = useState(defaultMapZoom);
  const zoomCookieName = 'ocZoom';

  const [selectedStation, setSelectedStation] = useState();

  const [mapPanned, setMapPanned] = useState(false);
  const [mapHasPanned, setMapHasPanned] = useState(false);
  const [mapCenterPoint, setMapCenterPoint] = useState(defaultMapCenter);
  const mapCenterPointCookieName = 'ocMapCenterPoint';

  const [clearSelectedMapElementOnNextPan, setClearSelectedMapElementOnNextPan] =
    useState(false);

  useEffect(() => {
    // Handle removing a searched-map-element on panning of the map
    if (selectedMapElement !== undefined) {
      // Only true after a search selection
      if (!mapPanned) {
        // Initialize one time trigger on end of initial movement to map element
        setClearSelectedMapElementOnNextPan(true); // Trigger set for next panning at end of initial pan to map element
      } else if (mapPanned && clearSelectedMapElementOnNextPan) {
        // Panning after first select has happened
        setSelectedMapElement(); // Clear selected map element
        setClearSelectedMapElementOnNextPan(false); // Reset trigger
      }
    }

    // Capture the first pan that happens
    if (mapPanned) {
      setMapHasPanned(true);
    }
  }, [mapPanned]);

  function handleSelectStation(station) {
    if (station) {
      setSelectedMapElement();
      setDataPinDrawerOpen(false);
      if (station.type === 'webcams') {
        setWebcamDialogData(station);
        setWebcamDialogOpen(true);
      } else {
        setSelectedStation(station);
      }
    } else {
      setSelectedStation();
    }
  }

  // MAIN MENU
  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  // WEBCAMS
  const [webcamDialogOpen, setWebcamDialogOpen] = useState(false);
  const [webcamDialogData, setWebcamDialogData] = useState(false);

  // DATA DRAWER
  const [dataPinDrawerOpen, setDataPinDrawerOpen] = useState(false);
  const [dataDrawerHidden, setDataDrawerHidden] = useState(false);

  // DATA PANEL
  const [dataPanelOpen, setDataPanelOpen] = useState(false);

  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [mapStyleLoaded, setMapStyleLoaded] = useState(false);

  useEffect(() => {
    if (!webcamDialogOpen) {
      setSelectedStation();
    }
  }, [webcamDialogOpen]);

  useEffect(() => {
    if (selectedStation?.type) {
      setDataPanelOpen(true);
    } else {
      setDataPanelOpen(false);
    }
  }, [selectedStation]);

  // SEARCH MENU
  const [flyToCoords, setflyToCoords] = useState([]);

  // GPS
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    setflyToCoords([pos.coords.longitude, pos.coords.latitude]);
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  // SELECTED MAP ELEMENT
  const [selectedMapElement, setSelectedMapElement] = useState();
  useEffect(() => {
    if (!dataPinDrawerOpen) {
      setSelectedMapElement();
      setMapHasPanned(false);
    }
  }, [dataPinDrawerOpen]);

  // VARIABLE BUTTONS
  const [variableSelectOpen, setVariableSelectOpen] = useState(false);

  const [selectedVariableName, setSelectedVariableName] = useState(
    mapLayers[0].variableName
  );
  const selectedVariableNameCookieName = 'ocSelectedVariableName';
  const selectedVariableObj = mapLayers.find(
    (e) => e.variableName === selectedVariableName
  );

  const [stationLocations, setStationLocations] = useState([]);
  const [drawerData, setDrawerData] = useState();
  const [pointLocations, setPointLocations] = useState();
  const debouncedPointLocations = useDebounce(pointLocations, 75);
  const [loadingDrawerData, setIsLoadingDrawerData] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [isChangingVariable, setIsChangingVariable] = useState(false);
  const [stationMenuOpen, setStationMenuOpen] = useState(false);
  const [showStationType, setShowStationType] = useState();
  const showStationTypeCookieName = 'ocShowStationType';
  const [scrollTime, setScrollTime] = useState();
  const debouncedVariableName = useDebounce(selectedVariableName, 200);
  const debouncedFlyToCoords = useDebounce(flyToCoords, 300);

  useEffect(() => {
    if (welcomeClosed !== undefined) {
      localStorage.setItem(welcomeClosedCookieName, JSON.stringify(welcomeClosed));
    } else {
      localStorage.removeItem(welcomeClosedCookieName);
    }
  }, [welcomeClosed]);

  useEffect(() => {
    if (showStationType !== undefined) {
      localStorage.setItem(showStationTypeCookieName, JSON.stringify(showStationType));
    } else {
      localStorage.removeItem(showStationTypeCookieName);
    }
  }, [showStationType]);

  useEffect(() => {
    if (selectedVariableName !== undefined) {
      localStorage.setItem(
        selectedVariableNameCookieName,
        JSON.stringify(selectedVariableName)
      );
    } else {
      localStorage.removeItem(selectedVariableNameCookieName);
    }
  }, [selectedVariableName]);

  useEffect(() => {
    if (zoom !== undefined) {
      localStorage.setItem(zoomCookieName, JSON.stringify(zoom));
    } else {
      localStorage.removeItem(zoomCookieName);
    }
  }, [zoom]);

  useEffect(() => {
    if (mapCenterPoint !== undefined) {
      localStorage.setItem(mapCenterPointCookieName, JSON.stringify(mapCenterPoint));
    } else {
      localStorage.removeItem(mapCenterPointCookieName);
    }
  }, [mapCenterPoint]);

  useEffect(() => {
    if (cookieConsent !== undefined) {
      localStorage.setItem(consentCookieName, JSON.stringify(cookieConsent));
      if (cookieConsent) {
        // right now there is only one service that we need to gather consent for: Clarity
        window.clarity('consent');
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
      }
    } else {
      localStorage.removeItem(consentCookieName);
    }
  }, [cookieConsent]);

  useEffect(() => {
    if (ocTermsAgreement !== undefined) {
      localStorage.setItem(ocTermsAgreementCookieName, JSON.stringify(ocTermsAgreement));
    } else {
      localStorage.removeItem(ocTermsAgreementCookieName);
    }
  }, [ocTermsAgreement]);

  const newsletterClosedCookieName = 'ocNewsletterClosed'; // Boolean, whether newsletter has been closed
  const newsletterClosedDateCookieName = 'ocNewsletterClosedDate'; // Date of last newsletter close
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false);
  const hideNewsletterModalPermanentlyCookieName = 'ocHideNewsletterModalPermanently';
  const [hideNewsletterModalPermanently, setHideNewsletterModalPermanently] =
    useState(false);

  useEffect(() => {
    if (hideNewsletterModalPermanently) {
      localStorage.setItem(hideNewsletterModalPermanentlyCookieName, true);
    }
  }, [hideNewsletterModalPermanently]);
  useEffect(() => {
    if (!mapLayerMaxTimes) return;
    if (!mapHasPanned) {
      setMapPanned(true);
      setMapHasPanned(true);
      setDataPinDrawerOpen(true);
    }
  }, [mapLayerMaxTimes]);

  useEffect(() => {
    if (localStorage.getItem(newsletterClosedCookieName) === null) {
      localStorage.setItem(newsletterClosedCookieName, false);
    } else if (
      localStorage.getItem(newsletterClosedCookieName) === 'false' || // Or reopen the subscribe modal after 30 days
      new Date(+localStorage.getItem(newsletterClosedDateCookieName)).getTime() +
      1000 * 60 * 60 * 24 * 30 <
      new Date().getTime()
    ) {
      setNewsletterModalOpen(true);
    } else if (localStorage.getItem(newsletterClosedCookieName) === 'true') {
      setNewsletterModalOpen(false);
    }
  }, []);

  // Function to fetch initial data
  const fetchInitialData = () => {
    const defaultLat = defaultMapCenter[1];
    const defaultLon = defaultMapCenter[0];
    const currentDateTime = generateDateTime(new Date());

    setDataPinDrawerOpen(true);
    setPointLocations([defaultLat, defaultLon]);
    setMapPanned(true);
    setMapHasPanned(true);

    fetchDrawerData(defaultLat, defaultLon, currentDateTime);
  };

  // Use effect to load initial data
  useEffect(() => {
    if (mapLayerMaxTimes && !initialDataLoaded) {
      fetchInitialData();
      setInitialDataLoaded(true);
    }
  }, [mapLayerMaxTimes, initialDataLoaded]);

  useEffect(() => {
    if (cookiesChecked && !newsletterModalOpen) {
      localStorage.setItem(newsletterClosedCookieName, true);
      localStorage.setItem(newsletterClosedDateCookieName, new Date().getTime());
    }
  }, [newsletterModalOpen]);

  // Either reads a cookie value into state if it exists, or loads state value into cookie
  function initializeToOrFromCookie(variable, setVariable, variableCookieName) {
    if (localStorage.getItem(variableCookieName) === null && variable !== null) {
      // No cookie, first time accessing site, set to settings (which are default)
      localStorage.setItem(variableCookieName, JSON.stringify(variable));
    } else if (localStorage.getItem(variableCookieName) !== null) {
      // Cookie present, set settings
      // catch JSON parsing issues:
      try {
        setVariable(JSON.parse(localStorage.getItem(variableCookieName)));
      } catch (e) {
        console.error('Error parsing JSON from cookie', e);
        // send message to sentry  but dont cause error
        Sentry.captureException(e);
        localStorage.setItem(variableCookieName, JSON.stringify(variable));
      }
    }
  }

  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

  const [cookiesChecked, setCookiesChecked] = useState(false);
  function getMapMaxTimes() {
    axios.get(server + `/map-layer-max-times`).then((response) => {
      setTileTimesLookup(response.data.modelTileTimeLookup);
      setMapLayMaxTimes(response.data.mapLayerMaxTimes);
    });
  }
  // On load, find existing cookies
  useLayoutEffect(() => {
    // Initialize settings
    initializeToOrFromCookie(settings, setSettings, settingsCookieName);
    // Initialize showStationType
    initializeToOrFromCookie(
      showStationType,
      setShowStationType,
      showStationTypeCookieName
    );
    // Initialize selectedVariableName
    initializeToOrFromCookie(
      selectedVariableName,
      setSelectedVariableName,
      selectedVariableNameCookieName
    );
    // Initialize zoom
    initializeToOrFromCookie(zoom, setZoom, zoomCookieName);
    // Initialize mapCenterPoint
    initializeToOrFromCookie(mapCenterPoint, setMapCenterPoint, mapCenterPointCookieName);
    // Initialize clarityConsentCookie
    initializeToOrFromCookie(cookieConsent, setCookieConsent, consentCookieName);
    // Initialize ocAgreementCookie
    initializeToOrFromCookie(
      ocTermsAgreement,
      setOCTermsAgreement,
      ocTermsAgreementCookieName
    );
    initializeToOrFromCookie(welcomeClosed, setWelcomeClosed, welcomeClosedCookieName);

    initializeToOrFromCookie(
      hideNewsletterModalPermanently,
      setHideNewsletterModalPermanently,
      hideNewsletterModalPermanentlyCookieName
    );

    // Must happen after all the initializaeToOrFromCookie calls
    setCookiesChecked(true);

    getMapMaxTimes();

    setInterval(() => {
      if (!dataPanelOpen) getMapMaxTimes();
    }, REFRESH_INTERVAL * 60 * 1000);

    // slow load or slow connection timer message
    const timer = setTimeout(() => {
      setShowTimeoutMessage(true);
    }, 5000);

    // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timer);
  }, []);

  function getStationsData(type) {
    fetch(`${server}${SSVStationsAPI}${type}`)
      .then((response) => response.json())
      .then((stationDataResponse) => {
        const stationLocationData = stationTypes.map((stationType) => ({
          stationData: {
            type: 'FeatureCollection',
            features: stationDataResponse.features.filter(
              (station) => station.properties.type === stationType.type
            ),
          },
          stationType: stationType.type,
          stationIcon: stationType.icon,
        }));

        setStationLocations(stationLocationData);
      })
      .catch((error) => {
        throw error;
      });
  }
  useEffect(() => {
    if (showStationType) {
      getStationsData(showStationType.type);
    }
  }, [showStationType]);
  // fetch datapin data for the drawer
  function fetchDrawerData(lat, lon, dateTime) {
    setIsLoadingDrawerData(true);
    if (landOrWater === 'land' && selectedVariableObj.isWaterVariable) {
      setDrawerData({ noDataReason: 'location' });
      setDataPinDrawerOpen(true);
      setIsLoadingDrawerData(false);
      setIsChangingVariable(false);

      return;
    }
    const apiCall = `${server}${SSVPointsAPI}timeseries`;

    const params = new URLSearchParams({
      lat,
      lon,
      base: landOrWater,
      dateTime,
      field: selectedVariableName,
      expanded: true, // legacy
    });

    fetch(`${apiCall}?${params.toString()}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data?.data?.length === 0 || data[selectedVariableName].value === null) {
            setDrawerData({ noDataReason: 'location' });
          } else setDrawerData(data);
          setDataPinDrawerOpen(true);
          setIsLoadingDrawerData(false);
          setIsChangingVariable(false);
        }
      })
      .catch((error) => {
        setDrawerData({});
        setIsLoadingDrawerData(false);
        setIsChangingVariable(false);
        console.error(error);
      });
  }

  const [sliderCurrentHour, setSliderCurrentHour] = useState(null);
  const [oldPointLocations, setOldPointLocations] = useState();
  const [oldSelectedVariableName, setOldSelectedVariableName] = useState();

  useEffect(() => {
    const newHour = new Date(sliderCurrentTime).getHours();
    if (
      // if dataDrawer is open and pointLocations or time changes, get new data
      mapLayerMaxTimes &&
      debouncedPointLocations?.length === 2 &&
      sliderCurrentTime <=
      new Date(mapLayerMaxTimes[selectedVariableName]).getTime() + 1000 * 60 * 60 &&
      dataPinDrawerOpen &&
      ((debouncedPointLocations !== undefined &&
        Array.isArray(debouncedPointLocations) &&
        debouncedPointLocations?.length === 2 &&
        debouncedPointLocations[0] !== undefined &&
        debouncedPointLocations[1] !== undefined &&
        !isEqual(debouncedPointLocations, oldPointLocations)) ||
        newHour !== sliderCurrentHour ||
        (initialDataLoaded &&
          landOrWater === 'land' &&
          !selectedVariableObj.isWaterVariable &&
          Object.keys(drawerData).length === 0) ||
        selectedVariableName !== oldSelectedVariableName)
    ) {
      fetchDrawerData(
        debouncedPointLocations[0],
        debouncedPointLocations[1],
        generateDateTime(new Date(sliderCurrentTime))
      );
      setOldPointLocations([...debouncedPointLocations]);
      setOldSelectedVariableName(selectedVariableName);
    } else if (
      mapLayerMaxTimes &&
      sliderCurrentTime >
      new Date(mapLayerMaxTimes[selectedVariableName]).getTime() + 1000 * 60 * 60
    ) {
      setSliderCurrentHour();
      setDrawerData({});
      setIsLoadingDrawerData(false);
      setIsChangingVariable(false);
    }
    setSliderCurrentHour(newHour);
    if (
      settings.useCrosshair &&
      mobileView &&
      newHour !== sliderCurrentHour &&
      debouncedPointLocations
    ) {
      setDataPinDrawerOpen(true);
    }
  }, [
    sliderCurrentTime,
    JSON.stringify(debouncedPointLocations),
    selectedVariableName,
    landOrWater,
  ]);

  useEffect(() => {
    setSliderPlayTime(false);
  }, [dataPanelOpen, stationMenuOpen, variableSelectOpen, mainMenuOpen, searchOpen]);

  function handleMenuClick() {
    console.log('handleMenuClick');
    window.clarity('event', 'Settings menu open');
    setMainMenuOpen(!mainMenuOpen);
  }

  function handleSearchClick() {
    window.clarity('event', 'Search click');
    setSearchOpen(!searchOpen);
  }

  function moveSelectedVariable(direction) {
    setIsChangingVariable(true);
    setIsLoadingDrawerData(true);
    // given an array, increment the selected variable
    const res = mapLayers.findIndex(
      (layer) => layer.variableName === selectedVariableName
    );

    // increase the index by 1, or go back to 0 if at the end
    let newIndex;
    if (direction === 'left') newIndex = res + 1 === mapLayers.length ? 0 : res + 1;
    else newIndex = res - 1 === -1 ? mapLayers.length - 1 : res - 1;

    setSelectedVariableName(mapLayers[newIndex].variableName);
  }

  function getTideStationByID(tideStationID) {
    const features = stationLocations.find((e) => e.stationType === 'tide_stations')
      .stationData.features;

    const foundTideObject = features.find(
      (e) => String(e.properties.id) === String(tideStationID)
    );

    return foundTideObject?.properties;
  }

  const crosshairWidth = 30;
  const drawerIsOpen = true;
  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <Welcome
          welcomeClosed={welcomeClosed}
          setWelcomeClosed={setWelcomeClosed}
          cookieConsent={cookieConsent}
          ocTermsAgreement={ocTermsAgreement}
          cookiesChecked={cookiesChecked}
          setCookieConsent={setCookieConsent}
          setOCTermsAgreement={setOCTermsAgreement}
        />
        <Backdrop
          open={
            !mapDataLoaded &&
            !mapStyleLoaded &&
            welcomeClosed !== undefined &&
            welcomeClosed &&
            ocTermsAgreement !== undefined &&
            newsletterModalOpen !== undefined &&
            cookieConsent !== undefined &&
            !newsletterModalOpen
          }
          sx={{
            zIndex: 2000,
            background: `linear-gradient(rgba(2, 53, 85, 0.9), rgba(2, 53, 85, 0.5))`,
          }}
        >
          <Stack alignItems="center" spacing={1} justifyContent="center">
            <Spinner />
            {showTimeoutMessage && (
              <>
                <Typography
                  color={theme.palette.warning.main}
                  variant="body"
                  sx={{
                    maxWidth: '350px',
                    padding: '15px 50px 0px 50px',
                    textAlign: 'center',
                  }}
                >
                  If OceanConnect&trade; does not load,
                  <br /> refresh or check connection
                </Typography>
                <WarningAmberRounded
                  sx={{ fontSize: '2rem', color: theme.palette.warning.main }}
                />
              </>
            )}
          </Stack>
        </Backdrop>
        <DataDrawer
          dataDrawerHidden={dataDrawerHidden}
          setDataDrawerHidden={setDataDrawerHidden}
          loadingDrawerData={isChangingVariable || !mapStyleLoaded}
          getTideStationByID={getTideStationByID}
          selectedVariableName={selectedVariableName}
          setDataPinDrawerOpen={setDataPinDrawerOpen}
          setDataPanelOpen={setDataPanelOpen}
          moveSelectedVariable={moveSelectedVariable}
          drawerData={drawerData || {}}
          setSelectedVariableName={setSelectedVariableName}
          settings={settings}
          changeUnits={changeUnits}
        />
        {((dataPanelOpen && pointLocations) || selectedStation?.type) && (
          <MemoizedDataPanel
            getTideStationByID={getTideStationByID}
            dataPanelOpen={dataPanelOpen}
            setDataPanelOpen={setDataPanelOpen}
            pointLocations={pointLocations}
            settings={settings}
            selectedStation={selectedStation}
            setSelectedStation={setSelectedStation}
            sliderCurrentTime={sliderCurrentTime}
            selectedVariableName={selectedVariableName}
            setSelectedVariableName={setSelectedVariableName}
            landOrWater={landOrWater}
            setScrollTime={setScrollTime}
            scrollTime={scrollTime}
            startDate={startDate}
            changeUnits={changeUnits}
            drawerData={drawerData}
          />
        )}
        {settings.useCrosshair &&
          dataPinDrawerOpen &&
          !dataDrawerHidden &&
          !selectedStation &&
          mapHasPanned && (
            <Box
              position="fixed"
              sx={{
                pointerEvents: 'none',
                top: `calc(50% - ${crosshairWidth / 2}px)`,
                zIndex: 100,
                left: 'calc(50% + 1px)',
                transform: 'translate(-50%, 0px)',
                height: `${crosshairWidth}px`,
              }}
            >
              <Crosshair
                crosshairWidth={crosshairWidth}
                ringColor={theme.palette.secondary.main}
                pipColor={theme.palette.primary.dark}
              />
            </Box>
          )}
        <Box
          position="fixed"
          sx={{
            top: drawerIsOpen && mobileView ? '135px' : '0px',
            zIndex: 1000,
            width: '100%',
            pointerEvents: 'none',
          }}
        >
          <Stack
            sx={{ padding: '57px 0px 0px 10px' }}
            direction="row"
            justifyContent="space-between"
          >
            <Stack
              sx={{
                padding: '0px 10px 0px 0px',

                marginTop: drawerIsOpen && mobileView ? '-80px' : '0px',
              }}
              direction="column"
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Legend
                selectedMapLayer={selectedVariableObj}
                settings={settings}
                changeUnits={changeUnits}
                dataPinDrawerOpen={drawerIsOpen}
              />
            </Stack>
            <Stack
              sx={{
                padding: '0px 10px 0px 0px',

                marginTop: drawerIsOpen && mobileView ? '-80px' : '0px',
              }}
              spacing={1}
              alignItems="center"
            >
              <Fab
                color="primary"
                sx={{
                  width: '55px',
                  aspectRatio: screenIsShort ? 1 : '',
                  minHeight: '90px',
                  height: 'fit-content',
                  borderRadius: '50px',
                  pointerEvents: 'auto',
                  backgroundColor: theme.palette.primary.dark,
                }}
                onClick={() => {
                  window.clarity('event', 'Layer Menu');
                  setVariableSelectOpen(true);
                }}
              >
                <Stack alignItems="center">
                  <StyledIcon
                    height={50}
                    width={30}
                    filename={selectedVariableObj?.icon}
                  />
                  {selectedVariableObj?.displayName === 'Currents' ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '10px',
                        wordBreak: 'break-word',
                        width: '55px',
                      }}
                    >
                      {selectedVariableObj?.variableSelectButtonName ||
                        selectedVariableObj?.displayNameShort}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '10px',
                        wordBreak: 'break-word',
                        width: '35px',
                      }}
                    >
                      {selectedVariableObj?.displayName.includes('Temperature')
                        ? selectedVariableObj?.displayName.slice(0, 8)
                        : selectedVariableObj?.displayName}
                    </Typography>
                  )}
                </Stack>
              </Fab>
              <VariableSelectMenu
                variableSelectOpen={variableSelectOpen}
                setVariableSelectOpen={setVariableSelectOpen}
                selectedMapLayer={selectedVariableObj}
                setSelectedVariableName={setSelectedVariableName}
                selectedVariableName={selectedVariableName}
              />
              <Webcams
                setWebcamDialogOpen={setWebcamDialogOpen}
                setSelectedStation={setSelectedStation}
                webcamDialogOpen={webcamDialogOpen}
                webcamDialogData={webcamDialogData}
              />
              <Fab
                color="primary"
                size="small"
                sx={{
                  width: '48px',
                  height: showStationType || screenIsShort ? '48px' : '90px', // Conditional height
                  borderRadius: '50px',
                  pointerEvents: 'auto',
                  '&.Mui-disabled': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.dark,
                  },
                  boxShadow: '70px',
                }}
                onClick={() => {
                  window.clarity('event', 'stationsMenuOpen');
                  setStationMenuOpen(!stationMenuOpen);
                }}
              >
                {showStationType ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    height="100%"
                  >
                    <Box
                      sx={{
                        height: '38px',
                        width: '38px',
                        borderRadius: '30px',
                        border: `2px solid ${theme.palette.secondary.main}`,
                        padding: '3px 2px 0px 0px',
                      }}
                    >
                      <img
                        src={require('./Images/' + showStationType.icon)}
                        height={30}
                        width={34}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    sx={{ gap: 0 }}
                  >
                    <Box
                      component="img"
                      src={AllStations}
                      sx={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '30px',
                        border: `2px solid ${theme.palette.secondary.main}`,
                        margin: '0',
                        paddingTop: '4px',
                        paddingLeft: '0px',
                      }}
                    />
                    {!screenIsShort && (
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: '8px',
                          wordBreak: 'break-word',
                          width: '42px',
                          marginTop: '2px',
                        }}
                      >
                        Stations on zoom
                      </Typography>
                    )}
                  </Stack>
                )}
              </Fab>
              <SearchMenu
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
                setSelectedMapElement={setSelectedMapElement}
                setSelectedStation={setSelectedStation}
                setflyToCoords={setflyToCoords}
                setPointLocations={setPointLocations}
              />
              <SettingsMenu
                mainMenuOpen={mainMenuOpen}
                setMainMenuOpen={setMainMenuOpen}
                settings={settings}
                setSettings={setSettings}
                cookieNames={[
                  settingsCookieName,
                  mapCenterPointCookieName,
                  zoomCookieName,
                  selectedVariableNameCookieName,
                  showStationTypeCookieName,
                  consentCookieName,
                  ocTermsAgreementCookieName,
                  welcomeClosedCookieName,
                  newsletterClosedCookieName,
                  newsletterClosedDateCookieName,
                  hideNewsletterModalPermanentlyCookieName,
                ]}
              />
              <StationsMenu
                stationMenuOpen={stationMenuOpen}
                setStationMenuOpen={setStationMenuOpen}
                showStationType={showStationType}
                setShowStationType={setShowStationType}
              />
              <Fab
                color="primary"
                size="small"
                sx={{
                  pointerEvents: 'auto',
                }}
                onClick={() => {
                  window.clarity('event', 'GPSButtonClick');
                  navigator.geolocation.getCurrentPosition(success, error, options);
                }}
              >
                <GpsFixed />
              </Fab>
              <Fab
                color="primary"
                size="small"
                sx={{
                  pointerEvents: 'auto',
                }}
                onClick={handleSearchClick}
              >
                <SearchIcon />
              </Fab>
              <Fab
                color="primary"
                size="small"
                sx={{
                  marginTop: '10px',
                  pointerEvents: 'auto',
                }}
                onClick={handleMenuClick}
              >
                <Settings />
              </Fab>
            </Stack>
          </Stack>
        </Box>
        <Map
          flyToCoords={debouncedFlyToCoords}
          stations={stationLocations || []}
          showStationType={showStationType}
          dataPinDrawerOpen={dataPinDrawerOpen}
          setDataPinDrawerOpen={setDataPinDrawerOpen}
          selectedStation={selectedStation}
          handleSelectStation={handleSelectStation}
          setPointLocations={setPointLocations}
          zoom={zoom}
          setZoom={setZoom}
          selectedVariableName={debouncedVariableName}
          sliderCurrentTime={sliderCurrentTime}
          setMapPanned={setMapPanned}
          setLandOrWater={setLandOrWater}
          settings={settings}
          setSettings={setSettings}
          mapLayerMaxTimes={mapLayerMaxTimes}
          mapCenterPoint={mapCenterPoint}
          setMapCenterPoint={setMapCenterPoint}
          dataPanelOpen={dataPanelOpen}
          webcamDialogOpen={webcamDialogOpen}
          cookiesChecked={cookiesChecked}
          tileTimesLookup={tileTimesLookup}
          setMapDataLoaded={setMapDataLoaded}
          setMapStyleLoaded={setMapStyleLoaded}
          mapStyleLoaded={mapStyleLoaded}
        />
        {/* put logo in bottom right corner, above timeslider */}
        {/* make it cliable, goes to a url */}

        <div
          className="logo-container"
          style={{
            position: 'fixed',
            bottom: '90px',
            right: '0px',
            zIndex: 1000,
            padding: '10px',
            pointerEvents: 'none', // Keep this for the container
          }}
        >
          <a
            href="/docs/"
            target="_blank"
            rel="noreferrer"
            style={{
              pointerEvents: 'auto', // Enable pointer events for the link
            }}
          >
            <img
              src={HorizontalLogo}
              alt="OceanConnect Logo"
              className="logo"
              width={100}
              height={30}
            />
          </a>
        </div>
        <TimeSlider
          sliderCurrentTime={sliderCurrentTime}
          setSliderCurrentTime={useMemo(() => debounce(setSliderCurrentTime, 100), [])}
          sliderStartTime={startDate.getTime()}
          numberOfDays={4}
          stepsPerDay={6}
          selectedMapLayer={selectedVariableObj}
          pointLocations={pointLocations}
          selectedStation={selectedStation}
          mapLayerMaxTimes={mapLayerMaxTimes}
          settings={settings}
          scrollTime={scrollTime}
          dataPanelOpen={dataPanelOpen}
          dataPinDrawerShown={dataPinDrawerOpen && mapHasPanned}
          landOrWater={landOrWater}
          sliderPlayTime={sliderPlayTime}
          setSliderPlayTime={setSliderPlayTime}
        />
        <MailchimpModal
          open={
            !hideNewsletterModalPermanently &&
            mapDataLoaded &&
            mapStyleLoaded &&
            welcomeClosed &&
            cookiesChecked &&
            ocTermsAgreement !== undefined &&
            newsletterModalOpen !== undefined &&
            cookieConsent !== undefined &&
            newsletterModalOpen
          }
          setNewsletterModalOpen={setNewsletterModalOpen}
          setHideNewsletterModalPermanently={setHideNewsletterModalPermanently}
        />
      </ThemeProvider>
    </CssBaseline>
  );
}
